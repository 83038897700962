import { LocaleContext } from '@/contexts/LocaleContext';
import { getLocaleUrl } from '@/helpers/getLocaleUrl';
import { GatsbyLinkProps, Link } from 'gatsby';
import React, { CSSProperties, FC, useContext } from 'react';

interface CustomLinkProps extends Omit<GatsbyLinkProps<{}>, 'ref'> {
  className?: string;
  external?: boolean;
  disabled?: boolean;
  withoutLocale?: boolean;
}

const isExternalLink = (path: string) =>
  path?.startsWith(`http://`) ||
  path?.startsWith(`https://`) ||
  path?.startsWith(`//`);

const CustomLink: FC<CustomLinkProps> = ({
  className,
  external = false,
  disabled = false,
  children,
  withoutLocale = false,
  to,
  ...props
}) => {
  const locale = useContext(LocaleContext);
  const customTo = withoutLocale ? to : getLocaleUrl(locale, to);
  const styles: CSSProperties = disabled ? { display: 'none' } : undefined;

  if (isExternalLink(to)) {
    return (
      <a
        className={className}
        href={to}
        target="_blank"
        style={styles}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <Link className={className} style={styles} {...props} to={customTo}>
      {children}
    </Link>
  );
};

export default CustomLink;
