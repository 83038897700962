export const TYPOGRAPHY = {
  title1SemiBold64: `
    font-size: 64px;
    line-height: 75px;
    font-weight: 600;
  `,
  title2SemiBold48: `
    font-size: 48px;
    line-height: 75px;
    font-weight: 600;
  `,
  title3SemiBold32: `
    font-size: 32px;
    line-height: 44px;
    font-weight: 600;
  `,
  title4Medium28: `
    font-size: 28px;
    line-height: 34px;
    font-weight: 500;
  `,
  headlineMedium22: `
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
  `,
  headlineSemiBold22: `
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
  `,
  body1Light18: `
    font-size: 18px;
    line-height: 32px;
    font-weight: 300;
  `,
  body1Regular18: `
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
  `,
  body1Medium18: `
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
  `,
  body1SemiBold18: `
    font-size: 18px;
    line-height: 32px;
    font-weight: 600;
  `,
  body2Light16: `
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
  `,
  body2Regular16: `
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  `,
  body2Medium16: `
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  `,
  body2Semibold16: `
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  `,
  body3Regular20: `
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
  `,
  body3Semibold20: `
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
  `,
  captionLight14: `
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
  `,
  captionRegular14: `
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  `,
  captionMedium14: `
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
  `,
};
