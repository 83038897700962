import { LINKS } from '@/constants';
import { useSocialMedias } from '@/hooks/useSocialMedias';
import { Facebook } from '@/ui/iconComponents/Facebook';
import { Instagram } from '@/ui/iconComponents/Instagram';
import { Twitter } from '@/ui/iconComponents/Twitter';
import { Youtube } from '@/ui/iconComponents/Youtube';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Image } from '../Image';

const SocialList: FC = () => {
  const socials = useSocialMedias();

  return (
    <Root>
      {socials.map((item) => (
        <Item key={item.id}>
          <ItemLink href={item.social.link} target="_blank">
            <Image
              localFile={item.social.icon?.localFile}
              alt={item.social.name}
            />
          </ItemLink>
        </Item>
      ))}
    </Root>
  );
};

const Root = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, 28px);
  gap: 14px;
  max-width: 155px;
`;

const Item = styled.li``;

const ItemLink = styled.a.attrs(() => ({
  target: '_blank',
}))``;

export default SocialList;
