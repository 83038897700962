import { ImageDataValue } from '@/types';
import { graphql, useStaticQuery } from 'gatsby';

interface SocialData {
  id: string;
  social: {
    name: string;
    link: string;
    icon: ImageDataValue;
  };
}

export function useSocialMedias(): SocialData[] {
  const { allStrapiSocialMedias } = useStaticQuery(graphql`
    query {
      allStrapiSocialMedias {
        nodes {
          id
          social {
            name
            link
            icon {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  return allStrapiSocialMedias.nodes;
}
