export const headerOverflowRemoveStyles = () => {
  const body = document.querySelector('body');
  const html = document.documentElement;
  const gatsbyWrapper: HTMLDivElement = document.querySelector(
    'body > div:first-child',
  );

  html.style.overflow = '';
  html.style.position = '';
  body.style.overflow = '';
  body.style.position = '';
  body.style.height = '';
  html.style.height = '';
  gatsbyWrapper.style.height = '';
};
