import * as React from 'react';
import { SVGProps } from 'react';

const Whatsapp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M47.631 8.138C42.39 2.893 35.421.003 27.995 0 12.695 0 .244 12.448.238 27.748A27.698 27.698 0 0 0 3.942 41.62L.004 56l14.715-3.858a27.74 27.74 0 0 0 13.265 3.378h.011c15.299 0 27.752-12.45 27.759-27.75.003-7.415-2.882-14.387-8.123-19.632ZM27.995 50.833h-.009a23.042 23.042 0 0 1-11.742-3.216l-.843-.5-8.732 2.29L9 40.897l-.55-.873A23.003 23.003 0 0 1 4.925 27.75c.005-12.717 10.354-23.063 23.08-23.063a22.922 22.922 0 0 1 16.312 6.764 22.924 22.924 0 0 1 6.75 16.317c-.005 12.718-10.354 23.065-23.07 23.065Z"
        fill="#E0E0E0"
      />
      <path
        d="m1.277 54.406 3.759-13.723a26.43 26.43 0 0 1-3.537-13.238C1.505 12.844 13.39.965 27.99.965c7.086.003 13.737 2.76 18.738 7.766 5.002 5.005 7.754 11.659 7.751 18.734-.006 14.601-11.89 26.481-26.488 26.481-.001 0 0 0 0 0h-.012a26.482 26.482 0 0 1-12.658-3.222L1.277 54.406Z"
        fill="url(#b)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.132 15.966c-.514-1.141-1.055-1.164-1.543-1.184-.4-.017-.857-.016-1.314-.016-.457 0-1.2.171-1.828.857-.629.687-2.4 2.345-2.4 5.718 0 3.374 2.457 6.633 2.8 7.091.342.457 4.743 7.6 11.711 10.35 5.792 2.283 6.97 1.829 8.228 1.714 1.257-.114 4.056-1.658 4.628-3.259.571-1.6.571-2.973.4-3.26-.172-.285-.629-.457-1.315-.8-.685-.342-4.056-2.001-4.684-2.23-.63-.228-1.086-.343-1.543.344-.457.686-1.77 2.23-2.17 2.686-.4.459-.8.516-1.486.173-.686-.344-2.894-1.067-5.514-3.403-2.038-1.817-3.414-4.061-3.814-4.748-.4-.685-.043-1.057.3-1.399.309-.307.687-.8 1.03-1.2.342-.4.456-.686.684-1.143.229-.458.115-.859-.057-1.202-.171-.342-1.504-3.733-2.113-5.089Z"
        fill="#fff"
      />
      <path
        d="M47.405 8.048C42.224 2.864 35.335.008 27.995.004 12.869.004.56 12.309.555 27.434a27.38 27.38 0 0 0 3.662 13.713L.324 55.361l14.546-3.814a27.422 27.422 0 0 0 13.113 3.339h.011c15.123 0 27.434-12.307 27.44-27.43.003-7.33-2.849-14.223-8.03-19.408Zm-19.41 42.205h-.01a22.779 22.779 0 0 1-11.607-3.178l-.834-.494-8.632 2.263 2.305-8.413-.543-.863a22.739 22.739 0 0 1-3.487-12.133c.005-12.57 10.236-22.798 22.816-22.798a22.659 22.659 0 0 1 16.124 6.686 22.66 22.66 0 0 1 6.674 16.13c-.005 12.572-10.236 22.8-22.806 22.8Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={27.878}
        y1={54.406}
        x2={27.878}
        y2={0.965}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#20B038" />
        <stop offset={1} stopColor="#60D66A" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h55.754v56H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Whatsapp;
