import { COLORS, TYPOGRAPHY } from '@/constants';
import { LocaleContext } from '@/contexts/LocaleContext';
import { useAllLocales } from '@/hooks/useAllLocales';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { LocalizationData, LocalizationSlugs } from '@/types';
import { Arrow } from '@/ui/iconComponents/Arrow';
import { navigate } from 'gatsby';
import React, {
  Context,
  FC,
  SyntheticEvent,
  useContext,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

interface LanguageSwitcherProps {
  context: Context<LocalizationData[]>;
}

const getLocalePathBySlug = (
  isEnLocale: boolean,
  slug: string,
  neededId: number,
  code: string,
) => {
  return isEnLocale ? `/${code}/${slug}/${neededId}` : `/${slug}/${neededId}`;
};

const LanguageSwitcher: FC<LanguageSwitcherProps> = ({ context }) => {
  const locale = useContext(LocaleContext);
  const localization = useContext(context);
  const allLocales = useAllLocales().filter((item) => item.code !== locale);
  const rootRef = useRef<HTMLDivElement>(null);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  useOnClickOutside(rootRef, () => setIsOpenDropdown(false));

  const handleButtonClick = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  const convertToLocalePath = (code: string, pathname: string) => {
    const isEnLocale = locale === 'en';

    if (pathname.split('/').indexOf(LocalizationSlugs.product) !== -1) {
      const neededId = localization.find((item) => item.locale === code).id;

      if (neededId) {
        return getLocalePathBySlug(
          isEnLocale,
          LocalizationSlugs.product,
          neededId,
          code,
        );
      }
    }

    if (pathname.split('/').indexOf(LocalizationSlugs.article) !== -1) {
      const neededId = localization.find((item) => item.locale === code).id;

      if (neededId) {
        return getLocalePathBySlug(
          isEnLocale,
          LocalizationSlugs.article,
          neededId,
          code,
        );
      }
    }

    return isEnLocale
      ? `/${code}${pathname}`
      : pathname.replace(`/${locale}`, '');
  };

  const handleDropdownLinkClick = (event: SyntheticEvent, code: string) => {
    if (localization && localization.length === 0) {
      event.preventDefault();
      return;
    }
    const { pathname } = window.location;

    const link = convertToLocalePath(code, pathname);

    navigate(link);
  };

  return (
    <Root ref={rootRef}>
      <MainButton onClick={handleButtonClick}>
        {locale}
        <StyledArrow color={COLORS.grayDarker2} />
      </MainButton>
      {isOpenDropdown && (
        <Dropdown>
          {allLocales.map((item) => (
            <DropdownItem key={item.id}>
              <DropdownLink
                onClick={(event) => handleDropdownLinkClick(event, item.code)}
              >
                {item.code}
              </DropdownLink>
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
`;

const MainButton = styled.button`
  position: relative;
  text-align: left;
  min-width: 104px;
  ${TYPOGRAPHY.body2Light16};
  color: ${COLORS.black1};
  padding: 5px 16px;
  text-transform: uppercase;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.lightGray};
  border-radius: 12px;
  outline: none;
  :focus {
    border-color: ${COLORS.purple};
  }
`;

const StyledArrow = styled(Arrow)`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%) rotate(90deg);
`;

const Dropdown = styled.ul`
  position: absolute;
  z-index: 2;
  width: 100%;
  left: 0;
  bottom: calc(100% + 8px);
  max-height: 250px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.lightGray2};
  border-radius: 12px;
`;

const DropdownItem = styled.li`
  position: relative;
  ::before {
    content: '';
    position: absolute;

    left: 16px;
    right: 16px;
    height: 1px;
    bottom: 0;
    background-color: #f0f1f5;
  }
  :last-child::before {
    content: none;
  }
`;

const DropdownLink = styled.button`
  display: flex;
  width: 100%;
  padding: 16px;
  ${TYPOGRAPHY.body2Light16};

  text-transform: uppercase;
  color: ${COLORS.black1};
  :hover {
    background-color: ${COLORS.lightGray2};
  }
`;

export default LanguageSwitcher;
