export const LINKS = {
  evUserSolutionPage: '/ev-user-solution',
  businessSolutionPage: '/business-solution',
  businessSolutionGuidlines: '',
  evUserSolutionGuidlines: '',
  shop: '',
  privacy: '',
  terms: '',
  articles: '/articles',
  news: '',
  reviews: '',
  smartsocket: '',
  partner: '',
  signIn: '',
  whatsapp: 'https://wa.me/message/NFUJK5LZZG3TH1',
};
