import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

export const requiredValidate = (value: string) =>
  value ? undefined : 'Required';

export const emailValidate = (value: string) =>
  yup.string().email().isValidSync(value) && value
    ? undefined
    : 'Incorrect email';

export const nameValidate = (value: string) =>
  yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
    .isValidSync(value) && value
    ? undefined
    : 'Incorrect name';

export const phoneValidate = (value: string) =>
  isPossiblePhoneNumber(value) ? undefined : 'Invalid phone number';

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    );
