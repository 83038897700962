import * as React from 'react';
import { SVGProps } from 'react';

interface ArrowProps extends SVGProps<SVGSVGElement> {
  color?: string;
  className?: string;
}

const Arrow = ({ color = '#554FF5', ...props }: ArrowProps) => (
  <svg
    width={10}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m2 2 6 6-6 6"
      stroke={color}
      strokeWidth={2.125}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Arrow;
