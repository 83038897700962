import { LocaleContext } from '@/contexts/LocaleContext';
import { useAllLocales } from '@/hooks/useAllLocales';
import React, { FC, useContext } from 'react';
import { LocalizationData, LocalizationSlugs, SeoData } from '@/types';
import { getLocaleUrl } from '@/helpers/getLocaleUrl';

import { Helmet } from 'react-helmet';

interface SeoProps {
  data: SeoData;
  localizations?: LocalizationData[];
}

const Seo: FC<SeoProps> = ({ data, localizations = [] }) => {
  const locale = useContext(LocaleContext);
  const allLocales = useAllLocales();
  const { keywords, metaDescription, metaTitle, sharedImage } = data;
  const hasWindow = typeof window !== 'undefined';
  const url = hasWindow ? window.location.href : '';
  const originUrl = hasWindow ? window.location.origin : '';
  const pathname = hasWindow ? window.location.pathname : '';
  const isLocalizationsPage =
    pathname.split('/').indexOf(LocalizationSlugs.product) ||
    pathname.split('/').indexOf(LocalizationSlugs.article);

  return (
    <Helmet>
      <html lang={locale} />
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      {sharedImage && <meta name="image" content={sharedImage.url} />}
      <meta name="keywords" content={keywords} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      {sharedImage && <meta property="og:image" content={sharedImage.url} />}

      <link rel="canonical" href={url} />
      {localizations.length === 0 &&
        isLocalizationsPage === -1 &&
        allLocales.map((item, index) => {
          const isEn = item.code === 'en';
          const pathWithoutLocale = pathname.replace(`/${locale}/`, '');

          const enPathname =
            locale === 'en' ? `${pathWithoutLocale}` : `/${pathWithoutLocale}`;
          const otherLangPathname =
            locale !== 'en'
              ? `/${item.code}/${pathWithoutLocale}`
              : `/${item.code}${pathWithoutLocale}`;

          const link = `${originUrl}${isEn ? enPathname : otherLangPathname}`;

          return (
            <link
              key={index}
              rel="alternate"
              hrefLang={item.code}
              href={link}
            />
          );
        })}

      {localizations.length !== 0 && (
        <link rel="alternate" hrefLang={locale} href={url} />
      )}

      {localizations.length !== 0 &&
        localizations.map((item, index) => {
          let link = '';

          if (pathname.split('/').indexOf(LocalizationSlugs.product) !== -1) {
            link = getLocaleUrl(
              item.locale,
              `/${LocalizationSlugs.product}/${item.id}`,
            );
          }

          if (pathname.split('/').indexOf(LocalizationSlugs.article) !== -1) {
            link = getLocaleUrl(
              item.locale,
              `/${LocalizationSlugs.article}/${item.id}`,
            );
          }

          return (
            <link
              key={index}
              rel="alternate"
              hrefLang={item.locale}
              href={originUrl + link}
            />
          );
        })}
    </Helmet>
  );
};

export default Seo;
