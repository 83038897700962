import { Input } from '@/ui/components/StyledInput';
import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';

interface TextFieldProps extends FieldRenderProps<string, HTMLInputElement> {}

const TextField: FC<TextFieldProps> = ({ input, meta, ...rest }) => {
  const isSuccess = meta.valid;
  const isError = meta.touched && meta.error;

  return <Input {...input} isSuccess={isSuccess} isError={isError} {...rest} />;
};

export default TextField;
