export enum DraftSystemValues {
  draft = 'draft',
  publish = 'publish',
}

export enum LocalizationSlugs {
  product = 'product',
  article = 'article',
}

export interface ImageDataValue {
  localFile: {
    childImageSharp: {
      gatsbyImageData: any;
    };
    extension: string;
    publicURL: string;
  };
}

export type ButtonThemes =
  | 'primary'
  | 'primarySmall'
  | 'secondary'
  | 'secondarySmall';

export interface SelectValue {
  label: string;
  value: any;
}

export interface ProductData {
  id: number;
  title: string;
  downloads?: DownloadFile[];
  mainImage: ImageDataValue;
}

export interface DownloadFile {
  title: string;
  file: {
    localFile: {
      publicURL: string;
    };
  };
}
export interface AccordionItemData {
  id: number | string;
  question: string;
  answer: string;
}

export interface FaqsData extends AccordionItemData {
  locale: string;
}

export interface DownloadBlockData {
  id: number | string;
  title: string;
  file: {
    localFile: {
      publicURL: string;
    };
  };
  icon: ImageDataValue;
}

export interface LocalizationData {
  id: number;
  locale: string;
}

export interface CarData {
  Vehicle_ID: number;
  Vehicle_Make: string;
  Vehicle_Model: string;
  Vehicle_Model_Version: string;
  Availability_Status: number;
  Availability_Date_From: string;
  Availability_Date_From_Estimate: boolean;
  Availability_Date_To?: null;
  Price_From_NL: number;
  Price_From_NL_Estimate: boolean;
  Price_From_UK: number;
  Price_From_UK_Estimate: boolean;
  Price_Grant_PICG_UK: number;
  Price_From_DE: number;
  Price_From_DE_Estimate: boolean;
  Drivetrain_Type: string;
  Drivetrain_Fuel: string;
  Drivetrain_Propulsion: string;
  Drivetrain_Propulsion_Estimate: boolean;
  Drivetrain_Power: number;
  Drivetrain_Power_HP: number;
  Drivetrain_Power_Estimate: boolean;
  Drivetrain_Torque: number;
  Drivetrain_Torque_Estimate: boolean;
  Performance_Acceleration: number;
  Performance_Acceleration_Estimate: boolean;
  Performance_Topspeed: number;
  Performance_Topspeed_Estimate: boolean;
  Range_WLTP: number;
  Range_WLTP_Estimate: boolean;
  Range_WLTP_TEH?: null;
  Range_NEDC?: null;
  Range_NEDC_Estimate: boolean;
  Range_Real: number;
  Range_Real_Mode: string;
  Range_Real_WHwy: number;
  Range_Real_WCmb: number;
  Range_Real_WCty: number;
  Range_Real_BHwy: number;
  Range_Real_BCmb: number;
  Range_Real_BCty: number;
  Efficiency_WLTP: number;
  Efficiency_WLTP_FuelEq: number;
  Efficiency_WLTP_V: number;
  Efficiency_WLTP_FuelEq_V: number;
  Efficiency_WLTP_CO2: number;
  Efficiency_WLTP_TEH?: null;
  Efficiency_WLTP_TEH_FuelEq?: null;
  Efficiency_WLTP_TEH_V?: null;
  Efficiency_WLTP_TEH_FuelEq_V?: null;
  Efficiency_WLTP_TEH_CO2?: null;
  Efficiency_NEDC?: null;
  Efficiency_NEDC_FuelEq?: null;
  Efficiency_NEDC_V?: null;
  Efficiency_NEDC_FuelEq_V?: null;
  Efficiency_NEDC_CO2?: null;
  Efficiency_Real: number;
  Efficiency_Real_FuelEq_V: number;
  Efficiency_Real_CO2: number;
  Efficiency_Real_WHwy: number;
  Efficiency_Real_WCmb: number;
  Efficiency_Real_WCty: number;
  Efficiency_Real_BHwy: number;
  Efficiency_Real_BCmb: number;
  Efficiency_Real_BCty: number;
  Charge_Plug: string;
  Charge_Plug_Estimate: boolean;
  Charge_Plug_Location: string;
  Charge_Plug_2_Location?: null;
  Charge_Plug_2_Optional?: null;
  Charge_Standard_Power: number;
  Charge_Standard_Phase: number;
  Charge_Standard_PhaseAmp: number;
  Charge_Standard_ChargeTime: number;
  Charge_Standard_ChargeSpeed: number;
  Charge_Standard_Estimate: boolean;
  Charge_Standard_Table: { [key: string]: ChargeStandartInfo };
  Charge_Alternative_Power?: null;
  Charge_Alternative_Phase?: null;
  Charge_Alternative_PhaseAmp?: null;
  Charge_Alternative_ChargeTime?: null;
  Charge_Alternative_ChargeSpeed?: null;
  Charge_Alternative_Table?: null;
  Charge_Option_Power?: null;
  Charge_Option_Phase?: null;
  Charge_Option_PhaseAmp?: null;
  Charge_Option_ChargeTime?: null;
  Charge_Option_ChargeSpeed?: null;
  Charge_Option_Table?: null;
  Fastcharge_Plug: string;
  Fastcharge_Plug_Estimate: boolean;
  Fastcharge_Plug_Location: string;
  Fastcharge_Power_Max: number;
  Fastcharge_Power_Avg: number;
  Fastcharge_ChargeTime: number;
  Fastcharge_ChargeSpeed: number;
  Fastcharge_Optional: boolean;
  Fastcharge_Estimate: boolean;
  Fastcharge_Table: { [key: string]: FastChargeInfo };
  Battery_Capacity_Useable: number;
  Battery_Capacity_Full: number;
  Battery_Capacity_Estimate: string;
  Battery_TMS: string;
  Battery_Warranty_Period: number;
  Battery_Warranty_Mileage: number;
  Dims_Length: number;
  Dims_Width: number;
  Dims_Width_Mirrors?: null;
  Dims_Height: number;
  Dims_LWH_Estimate: boolean;
  Dims_Wheelbase: number;
  Dims_Wheelbase_Estimate: boolean;
  Dims_Weight: number;
  Dims_Weight_Estimate: boolean;
  Dims_Weight_GVWR: number;
  Dims_Weight_MaxPayload: number;
  Dims_Bootspace: number;
  Dims_Bootspace_Max: number;
  Dims_Bootspace_Frunk?: null;
  Dims_Tow_Hitch: boolean;
  Dims_TowWeight_Unbraked: number;
  Dims_TowWeight_Braked: number;
  Dims_TowWeight_Estimate: boolean;
  Dims_TowWeight_VerticalLoad?: null;
  Dims_RoofLoad_Max: number;
  Misc_Body: string;
  Misc_Segment: string;
  Misc_Seats: number;
  Misc_Roofrails: boolean;
  Misc_Isofix: boolean;
  Misc_Isofix_Seats: number;
  Misc_TurningCircle: number;
  Misc_Vehicle_Platform?: null;
  Misc_Vehicle_Platform_Dedicated?: null;
  EuroNCAP_Rating: number;
  EuroNCAP_Year: number;
  EuroNCAP_Adult: number;
  EuroNCAP_Child: number;
  EuroNCAP_VRU: number;
  EuroNCAP_SA: number;
  Related_Vehicle_ID_Succesor?: null;
  EVDB_Detail_URL: string;
  Images?: string[] | null;
  Videos?: null;
}

export interface ChargeStandartInfo {
  EVSE_PhaseVolt: number;
  EVSE_PhaseAmp: number;
  EVSE_Phase: number;
  EVSE_Power: number;
  Charge_PhaseVolt: number;
  Charge_PhaseAmp: number;
  Charge_Phase: number;
  Charge_Power: number;
  Charge_Time: number;
  Charge_Speed: number;
}

export interface FastChargeInfo {
  Fastcharge_Power_Max: number;
  Fastcharge_Power_Avg: number;
  Fastcharge_ChargeTime: number;
  Fastcharge_ChargeSpeed: number;
  Fastcharge_Limited: boolean;
  Fastcharge_Avg_Limited: boolean;
}

export type ShopProductType = {
  id: string;
  name: string;
  description: string;
  slug: string;
  price: number;
  images: string[];
  __typename: string;
  model: string;
  brandString: string;
  manufacture: string;
  technicalSpecification: {
    key: string;
    value: string;
  }[];
  ratedMaxPower: number;
  chargingCategory: string;
};

export interface SeoData {
  keywords: string;
  metaDescription: string;
  metaTitle: string;
  sharedImage?: {
    url: string;
  };
}

export interface ArticleData {
  id: string;
  strapiId: number;
  seo: SeoData;
  content: string;
  created_at: string;
  locale: string;
  title: string;
  poster: {
    url: string;
    localFile: {
      childImageSharp: {
        gatsbyImageData: any;
      };
      extension: string;
      publicURL: string;
    };
  };
  excerption: string;
  category: {
    category: string;
  };
}
