import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC, HTMLProps } from 'react';
import ReactInputMask, { Props } from 'react-input-mask';
import styled from 'styled-components';

type InputRefProps = {
  mask?: string | Array<string | RegExp>;
} & Omit<Props, 'inputRef' | 'mask'>;

const Input = React.forwardRef<
  HTMLInputElement | ReactInputMask,
  InputRefProps
>(({ mask, maskPlaceholder, alwaysShowMask, ...props }, ref) => {
  return mask ? (
    <InputWithMask
      mask={mask}
      maskPlaceholder={maskPlaceholder}
      alwaysShowMask={alwaysShowMask}
      ref={ref}
      as={ReactInputMask}
      {...props}
    />
  ) : (
    <Root ref={ref} {...props} />
  );
});

const Root = styled.input`
  width: 100%;
  ${TYPOGRAPHY.body2Light16};
  padding: 10px 16px;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.lightGray};
  box-sizing: border-box;
  border-radius: 12px;
  color: ${COLORS.black1};
  outline: none;
  :focus {
    border-color: ${COLORS.purple};
    caret-color: ${COLORS.purple};
  }
  :disabled {
    border-color: ${COLORS.lightGray5};
    color: ${COLORS.lightGray6};
  }
  ::placeholder {
    color: ${COLORS.grayDarker2};
  }
  .error &,
  &.error {
    border-color: ${COLORS.red};
  }
  .success & {
    border-color: ${COLORS.green};
  }
`;

const InputWithMask = styled(Root)``;

export default Input;
