import { COLORS, LINKS } from '@/constants';
import { Logo } from '@/ui/iconComponents/Logo';
import React, { FC, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Container } from '../Container';
import Headroom from 'react-headroom';
import { CustomLink } from '../CustomLink';
import { useLocaleData } from '@/hooks/useLocaleData';
import { LocaleContext } from '@/contexts/LocaleContext';
import { headerOverflowRemoveStyles } from '@/utils';
import { headerOverflowAddStyles } from '@/utils';
import { HeaderMenuScroller } from '../HeaderMenuScroller';

const Header: FC = () => {
  const locale = useContext(LocaleContext);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isPin, setIsPin] = useState(false);
  const dataLocale = useMemo(() => useLocaleData(locale, 'header'), [locale]);
  const [yOffset, setYOffset] = useState(0);

  const menuItems = [
    {
      link: LINKS.evUserSolutionPage,
      label: dataLocale.evUser,
    },
    {
      link: LINKS.businessSolutionPage,
      label: dataLocale.business,
    },
    {
      link: LINKS.articles,
      label: dataLocale.blog,
    },
  ];

  const onPin = () => {
    setIsPin(true);
  };

  const onUnfix = () => {
    setIsPin(false);
  };

  const handleClickBurger = () => {
    if (innerWidth < 1100) {
      setYOffset(window.scrollY);

      if (isOpenMenu) {
        headerOverflowRemoveStyles();
        window.scrollTo(0, yOffset);

        setIsOpenMenu(false);
      } else {
        headerOverflowAddStyles();
        window.scrollTo(0, yOffset);
        setIsOpenMenu(true);
      }
    }
  };

  return (
    <>
      <Headroom
        disableInlineStyles={true}
        onPin={() => onPin()}
        onUnfix={() => onUnfix()}
      >
        <Root $isPin={isPin} $isOpenMenu={isOpenMenu}>
          <StyledContainer>
            <LogoLink
              to="/"
              onClick={() => {
                headerOverflowRemoveStyles();
                setIsOpenMenu(false);
              }}
            >
              <Logo color={isOpenMenu ? COLORS.white : COLORS.purple} />
            </LogoLink>
            <DesktopMenuWrapper $isOpenMenu={isOpenMenu}>
              <HeaderMenuScroller
                listLink={menuItems}
                onCloseMenu={() => handleClickBurger()}
                colorLink={COLORS.black1}
              />
            </DesktopMenuWrapper>
            <ButtonsWrapper>
              <Burger
                $isOpenMenu={isOpenMenu}
                $isPin={isPin}
                className={isOpenMenu ? 'active' : ''}
                onClick={() => handleClickBurger()}
              >
                <span></span>
              </Burger>
            </ButtonsWrapper>
            <MobileMenuWrapper $isOpenMenu={isOpenMenu}>
              <HeaderMenuScroller
                listLink={menuItems}
                onCloseMenu={() => handleClickBurger()}
                colorLink={COLORS.black1}
              />
            </MobileMenuWrapper>
          </StyledContainer>
        </Root>
      </Headroom>
    </>
  );
};

const Root = styled.header<{ $isOpenMenu: boolean; $isPin: boolean }>`
  background-color: ${({ $isOpenMenu, $isPin }) => {
    if ($isPin && $isOpenMenu) {
      return COLORS.mediumPurple;
    }
    if ($isPin && !$isOpenMenu) {
      return COLORS.white;
    }
    if (!$isPin && !$isOpenMenu) {
      return 'transparent';
    }
    if (!$isPin && $isOpenMenu) {
      return COLORS.mediumPurple;
    }
  }};

  .headroom--pinned & {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 1100px) {
    transition: none;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;

  @media (max-width: 500px) {
    padding-top: 46px;
  }
`;

const LogoLink = styled(CustomLink)`
  margin-right: 79px;
  @media (max-width: 1300px) {
    margin-right: 40px;
  }
  @media (max-width: 1100px) {
    margin-right: 0;
  }
`;

const MenuWrapper = styled.div<{ $isOpenMenu: boolean }>`
  @media (max-width: 1100px) {
    display: flex;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 83px;
    height: calc(100vh - 83px);
    z-index: 10;
    width: 100%;
    background-color: ${COLORS.mediumPurple};
  }
  @media (max-width: 600px) {
    max-width: none;
    transition: none;
  }
  @media (max-width: 500px) {
    top: 113px;
    height: calc(100vh - 113px);
  }
`;

const DesktopMenuWrapper = styled(MenuWrapper)`
  @media (max-width: 1100px) {
    display: none;
  }
`;

const MobileMenuWrapper = styled(MenuWrapper)`
  display: none;
  @media (max-width: 1100px) {
    display: ${({ $isOpenMenu }) => ($isOpenMenu ? 'flex' : 'none')};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const Burger = styled.button<{ $isOpenMenu: boolean; $isPin: boolean }>`
  display: none;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 17px;
  cursor: pointer;
  position: relative;
  z-index: 1;

  span,
  & span::before,
  & span::after {
    position: absolute;
    width: 25px;
    height: 1.5px;
    background-color: ${COLORS.purple};
    transition: all 0.3s ease;
  }
  & span::before {
    content: '';
    left: 0;
    top: -8px;
  }
  & span::after {
    content: '';
    left: 0;
    top: 8px;
  }

  &.active {
    span,
    span::before,
    span::after {
      background-color: ${({ $isOpenMenu, $isPin }) => {
        if ($isPin && $isOpenMenu) {
          return COLORS.white;
        }
        if ($isPin && !$isOpenMenu) {
          return COLORS.purple;
        }
        if (!$isPin && !$isOpenMenu) {
          return COLORS.purple;
        }
        if (!$isPin && $isOpenMenu) {
          return COLORS.white;
        }
      }};
    }

    span {
      transform: rotate(45deg);
      &::before {
        top: 0;
        transform: rotate(0);
      }
      &::after {
        top: 0;
        transform: rotate(90deg);
      }
    }
  }

  @media (max-width: 1100px) {
    display: flex;
    padding: 11px;
    box-sizing: content-box;
    margin-right: -11px;
  }
`;

export default Header;
