import { COLORS, LINKS, TYPOGRAPHY } from '@/constants';
import { LocaleContext } from '@/contexts/LocaleContext';
import { ProductLocaleIdContext } from '@/contexts/ProductLocaleIdContext';
import { convertToSlug } from '@/helpers/convertToSlug';
import {
  composeValidators,
  emailValidate,
  requiredValidate,
} from '@/helpers/fieldValidate';
import { useBusinessSolutionDetails } from '@/hooks/useBusinessSolutionDetails';
import { useLocaleData } from '@/hooks/useLocaleData';
import { LocalizationData } from '@/types';
import { UiButton } from '@/ui/components/UiButton';
import { Logo } from '@/ui/iconComponents/Logo';
import { FormApi } from 'final-form';
import { useMutation } from 'graphql-hooks';
import React, { Context, FC, useContext, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components';
import { Container } from '../Container';
import { CustomLink } from '../CustomLink';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { SocialList } from '../SocialList';
import { SuccessModal } from '../SuccessModal';
import { TextField } from '../TextField';

interface FormValues {
  email: string;
}

interface FooterProps {
  context?: Context<LocalizationData[]>;
  withoutLangSwitcher?: boolean;
}

const CREATE_USER_EMAIL_MUTATION = `mutation CreateUserEmailMutation($email: String!) {
  createUsersEmail(input: {data: {email: $email}}) {
    usersEmail {
      id
    }
    
  }
}`;

const Footer: FC<FooterProps> = ({
  context = ProductLocaleIdContext,
  withoutLangSwitcher = false,
}) => {
  const locale = useContext(LocaleContext);
  const [createUserEmail] = useMutation(CREATE_USER_EMAIL_MUTATION);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const businessSolutions = useBusinessSolutionDetails();
  const dataLocale = useMemo(
    () => useLocaleData(locale, ['footer', 'inputPlaceholders']),
    [locale],
  );

  const handleSubmit = async (values: FormValues, form: FormApi) => {
    const { data, error } = await createUserEmail({
      variables: values,
    });

    if (data) {
      setIsOpenModal(true);
      Object.keys(values).forEach((key) => {
        form.change(key, undefined);
        form.resetFieldState(key);
      });
    }
  };

  return (
    <Root>
      <SuccessModal
        title={dataLocale.footer.successModalTitle}
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
      <StyledContainer>
        <InfoSection>
          <LogoLink to="/">
            <Logo width={139} height={74} color={COLORS.purple} />
          </LogoLink>
          <Copyright>
            © {new Date().getFullYear()} {dataLocale.footer.copyright}
          </Copyright>
          <PoliciesList>
            <PolicyItem>
              <PolicyLink to={LINKS.terms} disabled={true}>
                {dataLocale.footer.terms}
              </PolicyLink>
            </PolicyItem>
            <PolicyItem>
              <PolicyLink to={LINKS.privacy} disabled={true}>
                {dataLocale.footer.privacy}
              </PolicyLink>
            </PolicyItem>
          </PoliciesList>
          <SocialLangSwitcher>
            <SocialsWrapper>
              <SocialsTitle>{dataLocale.footer.followUs}</SocialsTitle>
              <SocialList />
            </SocialsWrapper>
            {!withoutLangSwitcher && (
              <LangSwitcherWrapper>
                <SocialsTitle>{dataLocale.footer.language}</SocialsTitle>
                <LanguageSwitcher context={context} />
              </LangSwitcherWrapper>
            )}
          </SocialLangSwitcher>
        </InfoSection>
        <FooterMenus>
          <MenuWrapper>
            <MenuTitle>{dataLocale.footer.customer}</MenuTitle>
            <MenuList>
              <MenuItem>
                <MenuLink to={LINKS.evUserSolutionPage}>
                  {dataLocale.footer.solution}
                </MenuLink>
              </MenuItem>
              {/* <MenuItem>
                <MenuLink to={LINKS.evUserSolutionGuidlines} disabled={true}>
                  {dataLocale.guidlines}
                </MenuLink>
              </MenuItem>
              <MenuItem>
                <MenuLink to={LINKS.shop} disabled={true}>
                  {dataLocale.shop}
                </MenuLink>
              </MenuItem> */}
            </MenuList>
          </MenuWrapper>
          <MenuWrapper>
            <MenuTitle>{dataLocale.footer.business}</MenuTitle>
            <MenuList>
              {businessSolutions.map((item) => {
                const link = `${LINKS.businessSolutionPage}/#${convertToSlug(
                  item.title,
                )}`;
                return (
                  <MenuItem key={item.id}>
                    <MenuLink to={link}>{item.miniDetailCard.title}</MenuLink>
                  </MenuItem>
                );
              })}
            </MenuList>
          </MenuWrapper>
        </FooterMenus>
        <FormSubsribeWrap>
          <FormTitle>{dataLocale.footer.subscribe}</FormTitle>

          <Form
            onSubmit={(values: FormValues, form: FormApi) =>
              handleSubmit(values, form)
            }
            initialValues={{
              email: '',
            }}
            render={({ handleSubmit, submitting, invalid }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="email"
                  type="email"
                  placeholder={dataLocale.inputPlaceholders.email}
                  required
                  validate={composeValidators(requiredValidate, emailValidate)}
                  component={StyledEmailField}
                />

                <StyledSubmitBtn
                  text={dataLocale.footer.subscribeBtn}
                  customTheme="primary"
                  disabled={submitting || invalid}
                  onClick={handleSubmit}
                />
              </form>
            )}
          />
        </FormSubsribeWrap>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.footer`
  background-color: ${COLORS.lightGray4};
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 60px;
  padding-bottom: 60px;
  @media (max-width: 768px) {
    flex-direction: column;

    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

const SectionTitle = styled.p`
  ${TYPOGRAPHY.body3Semibold20};
  color: ${COLORS.black2};
`;

const InfoSection = styled.div`
  margin-right: 110px;
  @media (max-width: 1300px) {
    margin-right: 80px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const LogoLink = styled(CustomLink)`
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
`;

const Copyright = styled.p`
  ${TYPOGRAPHY.body2Regular16};
  margin-bottom: 16px;
  color: ${COLORS.black2};
`;

const PoliciesList = styled.ul`
  margin-bottom: 40px;
  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
`;

const PolicyItem = styled.li`
  margin-bottom: 16px;
  :last-child {
    margin-bottom: 0;
  }
`;

const PolicyLink = styled(CustomLink)`
  ${TYPOGRAPHY.body2Semibold16};

  color: ${COLORS.purple};
`;

const SocialLangSwitcher = styled.div`
  display: flex;
  align-items: flex-start;
`;

const SocialsWrapper = styled.div`
  margin-right: 50px;
`;

const LangSwitcherWrapper = styled.div``;

const SocialsTitle = styled(SectionTitle)`
  margin-bottom: 20px;
`;

const FooterMenus = styled.div`
  display: flex;
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

const MenuWrapper = styled.div`
  margin-right: 110px;
  :last-child {
    margin-right: 0;
  }
  @media (max-width: 1300px) {
    margin-right: 60px;
  }
  @media (max-width: 400px) {
    margin-right: 0;
    margin-bottom: 32px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const MenuTitle = styled(SectionTitle)`
  margin-bottom: 40px;
  @media (max-width: 400px) {
    margin-bottom: 24px;
  }
`;

const MenuList = styled.ul``;

const MenuItem = styled.li`
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0;
  }
`;

const MenuLink = styled(CustomLink)`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.grayDarker};
  transition: opacity 0.3s ease;
  :hover {
    opacity: 0.7;
  }
`;

const FormSubsribeWrap = styled.div`
  flex-grow: 1;
  max-width: 288px;
  margin-left: auto;
  @media (max-width: 1300px) {
    max-width: 250px;
  }
  @media (max-width: 1024px) {
    flex-grow: 0;
    width: 100%;
    max-width: 350px;
    margin-left: 0;
  }
  @media (max-width: 500px) {
    max-width: none;
  }
`;

const FormTitle = styled(SectionTitle)`
  margin-bottom: 10px;
`;

const FormDescription = styled.p`
  ${TYPOGRAPHY.captionRegular14};
  color: ${COLORS.grayDarker};
  margin-bottom: 20px;
  max-width: 200px;
`;

const StyledEmailField = styled(TextField)`
  width: 100%;
  margin-bottom: 20px;
`;

const StyledSubmitBtn = styled(UiButton)`
  width: 100%;
`;

export default Footer;
