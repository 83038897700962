export const COLORS = {
  purple: '#554FF5',
  purple2: '#574BF7',
  darkPurple: '#352ED3',
  darkPurple2: '#04006c',
  mediumPurple: '#2F288A',
  mediumPurple2: '#B9B7FF',
  lightPurple: '#E4E3FC',
  lightPurple2: '#D7D4FD',
  lightPurple3: '#D1CFFF',
  black1: '#14142A',
  black2: '#333',
  white: '#fff',
  red: '#DD343F',
  gray: '#A0A3BD',
  green: '#15CC8A',
  orange: '#FF6E0B',
  lightGray: '#DCDDE6',
  lightGray2: '#F9F9FA',
  lightGray3: '#F7F7FC',
  lightGray4: '#F8F8FD',
  lightGray5: '#E8E8E8',
  lightGray6: '#D5D5D5',
  lightGray7: '#C5C5CD',
  lightGray8: '#f0f1f5',
  lightGray9: '#e8e8f0',
  grayDarker: '#80838A',
  grayDarker2: '#4E4B66',
  grayDarker3: '#F2F2F2',
  grayOffWhite: '#fcfcfc',
};
