import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC } from 'react';
import styled from 'styled-components';
import { CustomLink } from '../CustomLink';

interface Item {
  link: string;
  label: string;
}

interface HeaderMenuScrollerProps {
  listLink: Item[];
  onCloseMenu: () => void;
  colorLink?: string;
}

const HeaderMenuScroller: FC<HeaderMenuScrollerProps> = ({
  listLink,
  onCloseMenu,
  colorLink = COLORS.white,
}) => {
  return (
    <Root>
      <MainMenuNav>
        <MainMenuList>
          {listLink.map((item) => {
            return (
              <MainMenuItem key={item.label}>
                <MenuLink
                  $color={colorLink}
                  onClick={onCloseMenu}
                  to={item.link}
                >
                  {item.label}
                </MenuLink>
              </MainMenuItem>
            );
          })}
        </MainMenuList>
      </MainMenuNav>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1100px) {
    flex-direction: column;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const MainMenuNav = styled.nav`
  margin-right: 97px;
  @media (max-width: 1300px) {
    margin-right: 60px;
  }
  @media (max-width: 1100px) {
    margin-right: 0;
    margin-bottom: 48px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const MainMenuList = styled.ul`
  display: flex;
  align-items: center;
  @media (max-width: 1100px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const MainMenuItem = styled.li`
  margin-right: 60px;
  :last-child {
    margin-right: 0;
  }
  @media (max-width: 1300px) {
    margin-right: 40px;
  }
  @media (max-width: 1100px) {
    margin-right: 0;
    margin-bottom: 32px;
  }
`;

const MenuLink = styled(CustomLink)<{ $color: string }>`
  ${TYPOGRAPHY.body2Regular16};
  color: ${({ $color }) => $color};
  transition: opacity 0.2s ease-in-out;
  :hover {
    opacity: 0.6;
  }

  .headroom--pinned & {
    color: ${COLORS.black1};
  }
  @media (max-width: 1100px) {
    ${TYPOGRAPHY.headlineMedium22};
    color: ${COLORS.lightGray2};
  }
`;

export default HeaderMenuScroller;
