import { COLORS } from '@/constants';
import { FC } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';

ReactModal.setAppElement('body');

ReactModal.defaultStyles = {
  overlay: {
    ...ReactModal.defaultStyles.overlay,
    backgroundColor: 'rgba(20, 20, 42, 0.4)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
  },
};

const Modal: FC<ReactModal.Props> = ({
  onRequestClose,
  children,
  ...props
}) => {
  return (
    <Root {...props}>
      <Wrapper>{children}</Wrapper>
    </Root>
  );
};

const Root = styled(ReactModal)`
  background-color: ${COLORS.white};
  border-radius: 16px;
  @media (max-width: 768px) {
    margin-right: 16px;
    margin-left: 16px;
  }
`;

const Wrapper = styled.div`
  padding: 40px;
  @media (max-width: 768px) {
    padding: 20px 16px;
  }
`;

export default Modal;
