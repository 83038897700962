import { graphql, useStaticQuery } from 'gatsby';

interface LocaleData {
  id: string;
  isDefault: boolean;
  code: string;
  name: string;
}

export function useAllLocales(): LocaleData[] {
  const { allStrapiI18NLocales } = useStaticQuery(graphql`
    query {
      allStrapiI18NLocales {
        nodes {
          id
          isDefault
          code
          name
        }
      }
    }
  `);

  return allStrapiI18NLocales.nodes;
}
