import * as React from 'react';
import { SVGProps } from 'react';

const Cross = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m12.002 12 5.243 5.243m-10.485 0 5.242-5.242-5.242 5.242ZM17.245 6.758 12.002 12l5.243-5.243ZM12.002 12 6.76 6.758 12.002 12Z"
      stroke="#4E4B66"
      strokeWidth={2.125}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Cross;
