import { LocaleContext } from '@/contexts/LocaleContext';
import { ImageDataValue, ProductData } from '@/types';
import { graphql, useStaticQuery } from 'gatsby';
import { useContext } from 'react';

interface BusinessSolutionsDetailsData {
  id: string;
  locale: string;
  title: string;
  subtitle: string;
  miniDetailCard: {
    id: number;
    title: string;
    description: string;
    buttonText: string;
    image: ImageDataValue;
  };
  detailCard: {
    id: number;
    productsTitle: string;
    userCasesTitle: string;
    userCasesDescription: string;
    challengesTitle: string;
    challengesDescription: string;
    detailType: string;
    image: ImageDataValue;
    tabletImage: ImageDataValue;
    mobileImage: ImageDataValue;
  };
  products: ProductData[];
}

export function useBusinessSolutionDetails(): BusinessSolutionsDetailsData[] {
  const locale = useContext(LocaleContext);
  const { allStrapiBusinessSolutionsDetails } = useStaticQuery(graphql`
    query {
      allStrapiBusinessSolutionsDetails {
        nodes {
          id
          locale
          title
          subtitle
          miniDetailCard {
            id
            title
            description
            buttonText
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                publicURL
                extension
              }
            }
          }
          detailCard {
            id
            productsTitle
            userCasesTitle
            userCasesDescription
            challengesTitle
            challengesDescription
            detailType
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
            tabletImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
            mobileImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
          products {
            id
            title
            mainImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                publicURL
                extension
              }
            }
          }
        }
      }
    }
  `);

  return allStrapiBusinessSolutionsDetails.nodes.filter(
    (item) => item.locale === locale,
  );
}
