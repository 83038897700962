import { COLORS, TYPOGRAPHY } from '.';

export const BUTTONS_THEME = {
  primary: `
    ${TYPOGRAPHY.body2Regular16};
    color: ${COLORS.white};
    padding: 16px 37px;
    background-color: ${COLORS.purple};
    text-align: center;
    border-radius: 20px;
    outline: none;
    :hover {
      background-color: ${COLORS.darkPurple};
    }
    :focus {
      background-color: ${COLORS.darkPurple};
      box-shadow: 0 0 0px 6px rgba(85, 79, 245, 0.2);
    }
    :active {
      background-color: ${COLORS.darkPurple};
      box-shadow: 0px 5px 14px rgba(53, 46, 211, 0.39);
    }
    :disabled {
      color: ${COLORS.white};
      background-color: ${COLORS.gray};
    }
  `,
  primarySmall: `
  ${TYPOGRAPHY.body2Regular16};
    color: ${COLORS.white};
    padding: 8px 37px;
    background-color: ${COLORS.purple};
    text-align: center;
    border-radius: 12px;
    outline: none;
    :hover {
      background-color: ${COLORS.darkPurple};
    }
    :focus {
      background-color: ${COLORS.darkPurple};
      box-shadow: 0 0 0px 6px rgba(85, 79, 245, 0.2);
    }
    :active {
      background-color: ${COLORS.darkPurple};
      box-shadow: 0px 5px 14px rgba(53, 46, 211, 0.39);
    }
    :disabled {
      color: ${COLORS.white};
      background-color: ${COLORS.gray};
    }
  `,
  secondary: `
    ${TYPOGRAPHY.body2Regular16};
    color: ${COLORS.purple};
    padding: 16px 37px;
    background-color: ${COLORS.lightPurple};
    text-align: center;
    border-radius: 20px;
    outline: none;
    :hover {
      background-color: ${COLORS.mediumPurple2};
    }
    :focus {
      background-color: ${COLORS.lightPurple};
      box-shadow: 0 0 0px 6px rgba(189, 187, 253, 0.2);
    }
    :active {
      background-color: ${COLORS.mediumPurple2};
      box-shadow: 0px 5px 14px rgba(85, 79, 220, 0.21);
    }
  `,
  secondarySmall: `
    ${TYPOGRAPHY.body2Regular16};
    color: ${COLORS.purple};
    padding: 8px 37px;
    background-color: ${COLORS.lightPurple};
    text-align: center;
    border-radius: 12px;
    outline: none;
    :hover {
      background-color: ${COLORS.mediumPurple2};
    }
    :focus {
      background-color: ${COLORS.lightPurple};
      box-shadow: 0 0 0px 6px rgba(189, 187, 253, 0.2);
    }
    :active {
      background-color: ${COLORS.mediumPurple2};
      box-shadow: 0px 5px 14px rgba(85, 79, 220, 0.21);
    }
  `,
  linkText: `
    ${TYPOGRAPHY.body2Regular16};
    color: ${COLORS.purple};
    display: inline-block;
    padding: 10px 44px 10px 10px;
    position: relative;
    border-radius: 10px;
    transition: background-color 0.3s ease;
    outline: none;
    :hover {
      background-color: ${COLORS.lightGray2};
    }
    :focus {
      background-color: ${COLORS.lightGray2};
      box-shadow: 0 0 0px 6px rgba(247, 247, 252, 0.4);
    }
  `,
};
