import { COLORS, LINKS } from '@/constants';
import { Whatsapp } from '@/ui/iconComponents/Whatsapp';
import React, { FC } from 'react';
import styled from 'styled-components';

const WhatsappLink: FC = () => {
  return (
    <Root href={LINKS.whatsapp} target="_blank">
      <WhatsappIcon />
    </Root>
  );
};

const Root = styled.a`
  display: inline-flex;
  position: fixed;
  z-index: 10;
  padding: 12px;
  right: 28px;
  bottom: 28px;
  background-color: ${COLORS.white};
  border-radius: 23px;
  box-shadow: 0px 8px 22px rgba(204, 204, 213, 0.33);
  @media (max-width: 1024px) {
    padding: 9px;
    right: 32px;
    border-radius: 16px;
    box-shadow: 0px 6px 15px rgba(204, 204, 213, 0.33);
  }
  @media (max-width: 768px) {
    right: 16px;
    bottom: 16px;
  }
`;

const WhatsappIcon = styled(Whatsapp)`
  @media (max-width: 1024px) {
    width: 39px;
    height: 39px;
  }
`;

export default WhatsappLink;
