export const headerOverflowAddStyles = () => {
  const body = document.querySelector('body');
  const html = document.documentElement;
  const gatsbyWrapper: HTMLDivElement = document.querySelector(
    'body > div:first-child',
  );
  const overflowStyles = `overflow: hidden;`;
  html.style.cssText = overflowStyles;
  body.style.cssText = overflowStyles;
  html.style.height = '100vh';
  body.style.height = '100%';
  gatsbyWrapper.style.height = '100vh';
};
