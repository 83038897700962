import { COLORS, TYPOGRAPHY } from '@/constants';
import { LocaleContext } from '@/contexts/LocaleContext';
import { useLocaleData } from '@/hooks/useLocaleData';
import { UiButton } from '@/ui/components/UiButton';
import { Cross } from '@/ui/iconComponents/Cross';
import React, { FC, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Modal } from '../Modal';

interface SuccessModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

const SuccessModal: FC<SuccessModalProps> = ({ title, isOpen, onClose }) => {
  const locale = useContext(LocaleContext);
  const dataLocale = useMemo(
    () => useLocaleData(locale, ['buttons']),
    [locale],
  );
  return (
    <StyledModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <div>
        <Head>
          <Title>{title}</Title>
          <button onClick={() => onClose()}>
            <Cross />
          </button>
        </Head>

        <StyledUiButton
          text={dataLocale.buttons.close}
          onClick={() => onClose()}
          customTheme="primary"
        />
      </div>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: 536px;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 70px;
`;

const Title = styled.p`
  ${TYPOGRAPHY.headlineSemiBold22};
  color: ${COLORS.black1};
  margin-right: 30px;
`;

const StyledUiButton = styled(UiButton)`
  width: 100%;
`;

export default SuccessModal;
