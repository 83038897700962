import React, { ButtonHTMLAttributes, FC } from 'react';
import { BUTTONS_THEME } from '@/constants';
import styled from 'styled-components';
import { ButtonThemes } from '@/types';

interface UiButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  onClick: () => void;
  className?: string;
  isLoading?: boolean;
  customTheme: ButtonThemes;
}

const UiButton: FC<UiButtonProps> = ({
  text,
  onClick,
  customTheme = 'primary',
  className,
  isLoading,
  ...props
}) => {
  return (
    <Root
      className={className}
      onClick={onClick}
      $theme={customTheme}
      {...props}
    >
      {text}
    </Root>
  );
};

const Root = styled.button<{ $theme: string }>`
  ${({ $theme }) => BUTTONS_THEME[$theme]};
  cursor: pointer;
`;

export default UiButton;
