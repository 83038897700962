import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

interface ImageProps {
  localFile: {
    childImageSharp: any | null;
    extension: string;
    publicURL: string;
  };
  alt: string;
  className?: string;
  loading?: 'eager' | 'lazy';
}
const Image: FC<ImageProps> = ({
  localFile,
  alt,
  className,
  loading = 'lazy',
}) => {
  if (localFile) {
    return (
      <>
        {!localFile.childImageSharp && localFile.extension === 'svg' ? (
          <img className={className} src={localFile.publicURL} alt={alt} />
        ) : (
          <GatsbyImage
            className={className}
            image={localFile.childImageSharp.gatsbyImageData}
            alt={alt}
            loading={loading}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default Image;
