import React, { FC } from 'react';

interface MainHeadingProps {
  text: string;
}

const MainHeading: FC<MainHeadingProps> = ({ text }) => {
  return <h1 className="visually-hidden">{text}</h1>;
};

export default MainHeading;
