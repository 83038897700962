import data from '@/locales/locales.json';

export function useLocaleData(lang: string, ns: string | string[]) {
  if (Array.isArray(ns)) {
    const neededLocales = {};
    ns.map(
      (item) =>
        (neededLocales[item] = data[lang]
          ? data[lang][item]
          : data['en'][item]),
    );
    return neededLocales;
  } else {
    return data[lang] ? data[lang][ns] : data['en'][ns];
  }
}
