import { COLORS } from '@/constants';
import React, { FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Input } from '../Input';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  isError?: boolean | string;
  isSuccess?: boolean;
}

const StyledInput: FC<InputProps> = ({
  className,
  isError = false,
  isSuccess = false,
  ...props
}) => {
  const errorClass = isError ? 'error' : '';
  const successClass = isSuccess ? 'success' : '';

  return (
    <Root className={`${className} ${successClass}`}>
      <Input className={`${errorClass}`} {...props} />

      {isError && <ErrorText>{isError}</ErrorText>}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  &.success {
    ::before {
      content: '';
      position: absolute;
      right: 21px;
      top: 17px;
      width: 6px;
      height: 14px;
      border: solid ${COLORS.green};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
`;

const ErrorText = styled.span`
  margin-top: 4px;
  transition: transform 0.3s ease;
  font-size: 10px;
  line-height: 14px;
  color: ${COLORS.red};
`;

export default StyledInput;
